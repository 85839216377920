<template>
    <iframe style="width: 400px;height: 850px" src="http://47.105.214.161:7792/%E6%9F%A5%E7%9C%8B%E8%B5%84%E6%BA%90.html">
    </iframe>
</template>

<script>
export default {
  name: "articalDetail",
}
</script>

<style scoped>

</style>